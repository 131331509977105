'use client';

import { cva } from 'class-variance-authority';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import { CSSProperties, ReactNode } from 'react';

import { Container } from '@/components/container';
import { HeroConfig } from '@/config-schema/home-page';
import { cn } from '@/utils/tailwind';

type Config = HeroConfig['config'];

export type HeroProps = Config & {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  containerClassName?: string;
};

const heroClasses = cva([], {
  variants: {
    textBase: {
      'hero-1': 'text-left rtl:text-right',
      'hero-2': 'text-center m-auto',
    },
  },
});

export function Hero({
  className,
  backgroundColor,
  imageUrl,
  title = {
    text: '',
    color: '',
    transform: null,
    className: '',
  },
  description,
  variant,
  containerClassName,
  children,
}: HeroProps) {
  // Will fallback to a div with the background color if no image is provided
  const heroImageElement = imageUrl ? (
    <img
      className="absolute inset-0 z-[-1] h-full w-full object-cover object-center"
      style={{
        backgroundColor: backgroundColor || undefined,
      }}
      src={imageUrl}
      alt="hero banner"
      // eslint-disable-next-line react/no-unknown-property
      fetchPriority="high"
    />
  ) : (
    <div
      className="absolute inset-0 z-[-1] h-full w-full object-cover object-left-top"
      style={{
        backgroundColor: backgroundColor || undefined,
      }}
    ></div>
  );

  return (
    <div
      className={cn(
        'w-100',
        'h-[360px]',
        'relative',
        'flex',
        'place-items-center',
        'bg-overlay',
        className,
      )}
    >
      {heroImageElement}
      <Container
        className={cn(
          heroClasses({ textBase: variant }),
          'relative',
          'z-0',
          containerClassName,
        )}
      >
        {title.text ? (
          <LazyMotion features={domAnimation}>
            <AnimatePresence>
              <m.h1
                className={cn(
                  title.transform,
                  'text-page-title',
                  'font-heading',
                  'line-clamp-1',
                  title.className,
                )}
                style={{
                  color: title.color || undefined,
                }}
                data-testid={'hero-title'}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                animate={{ opacity: 1 }}
              >
                {title.text}
              </m.h1>
            </AnimatePresence>
          </LazyMotion>
        ) : null}

        {description ? (
          <p
            className={cn(
              description.transform,
              description.className,
              'text-page-subtitle',
            )}
            style={{
              color: description.color || undefined,
            }}
            data-testid="hero-subtitle"
          >
            {description.text}
          </p>
        ) : null}
        {children}
      </Container>
    </div>
  );
}
