import { useTranslations } from 'next-intl';
import { ComponentProps } from 'react';

import { useDisplayDate } from '@/hooks/utils/use-display-date';
import { GiftCardCampaignSchema } from '@/schema/campaigns/campaign.schema';
import { cn } from '@/utils/tailwind';
import { getFeaturedCampaign } from './utils/gift-card-campaign-utils';

interface CampaignDiscountLabelProps extends ComponentProps<'div'> {
  eligibleCampaigns: GiftCardCampaignSchema[];
  showTimeEnd?: boolean;
}

export function CampaignDiscountLabel({
  eligibleCampaigns,
  className,
  showTimeEnd = true,
}: CampaignDiscountLabelProps) {
  const t = useTranslations('campaignDiscountLabel');
  const appliedCampaign = getFeaturedCampaign(eligibleCampaigns);
  const dateFormatted = useDisplayDate(
    appliedCampaign?.qualifyingEndTime ?? undefined,
  );

  // We are assuming that all denominations will have the same discount configuration for now.
  const appliedDenomination = appliedCampaign?.appliedDenominations[0];

  const labelText =
    appliedDenomination?.discountPercentage ||
    appliedDenomination?.discountAmount
      ? t('label_type_hasEndTime_discount_amount_date', {
          type: appliedDenomination.discountType,
          hasEndTime: Boolean(
            showTimeEnd && appliedCampaign?.qualifyingEndTime,
          ),
          discount: (appliedDenomination.discountPercentage ?? 0) / 100,
          amount: appliedDenomination.discountAmount,
          date: dateFormatted,
        })
      : '';

  if (!appliedCampaign || !appliedDenomination) {
    return null;
  }

  return (
    <div
      className={cn(
        'gap-2 rounded bg-primary-200 px-2 text-primary',
        className,
      )}
    >
      {labelText}
    </div>
  );
}
